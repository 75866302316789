<template>
    <section class="edit-category">
        <Loader :loading="showLoader" />
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title float-left">Edit Menu</h3>
                                <router-link class="float-right" to="/website-management/menu">
                                    <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="pt-2" @submit.prevent="MenuPage">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <input type="text" class="form-control" v-model="editMenu.name" :class="{
                                                    'is-invalid': submitted && $v.editMenu.name.$error,
                                                }" />
                                                <div v-if="submitted && !$v.editMenu.name.required"
                                                    class="invalid-feedback">
                                                    Name is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Url</label>
                                                <input type="text" class="form-control" v-model="editMenu.url" :class="{
                                                    'is-invalid': submitted && $v.editMenu.url.$error,
                                                }" />
                                                <div v-if="submitted && !$v.editMenu.url.required"
                                                    class="invalid-feedback">
                                                    Url is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for=""> Status </label>
                                                <div class="form-group">
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="editMenu.active" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Sort</label>
                                                <input type="number" class="form-control" v-model="editMenu.sort" />
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Slug</label>
                                                <input type="text" class="form-control" v-model="editMenu.slug" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group float-right mb-0 pt-3">
                                        <button class="btn btn btn-add btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Loader from "../../../components/Loader";
export default {
    name: "add-invoice",
    components: {
        Loader,
    },
    data() {
        return {
            showLoader: false,
            submitted: false,
            editMenu: {
                name: "Products",
                url: "https://shoplattice-website.codestager.com/shop/products",
                slug: "products",
                active: 1,
                sort: "1"
            },
        };
    },
    validations: {
        editMenu: {
            name: { required },
            url: { required },
        },
    },
    mounted() { },
    methods: {
        async MenuPage() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.showLoader = true;
            console.log(this.editMenu);

            // const data = await setingsApi.Menu({
            //     ...this.Menu,
            // });
            this.showLoader = false;
            this.$router.push("/website-management/menu");
            if (data.status == 200) {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: data.messages,
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: data.messages,
                });
            }
        },
    },
};
</script>
